import React from "react"

import { 
  Team, 
  BeautyParlor, 
  BarberShop, 
  Contact, 
  Faq,
  Testimonials,
  Instagram
} from './../sections'

import { 
  Layout,
  Impact,
  Text,
  SpotifyBar,
} from './../components'

// import impactVideo from './../videos/optimized/impact_video.mp4'
import impactImage from '../images/Collective-Haus-Sudbury_Team_06-2023.jpg';

const IndexPage = () => {
  return (
    <>
      <Layout>
        <main>
          <Impact
            mediaType="image"
            mediaSrc={impactImage}
            alt="The Collective Haus crew of barbers and stylists hanging out in the barbershop."
          />
          <Text spacing="lg">
            <div className="columns">
              <div className="column is-10">
                <h2 className="h6">
                  What The Flipping Heck Is Collective Haus?
                </h2>
                <p className="large">
                  A collection of young, creative, misfit artists and stylists.
                </p>
              </div>
            </div>
          </Text>
          <Team />
          <Text alignText="right" watermarkNum={2}>
            <div className="columns">
              <div className="column is-10 is-offset-2 has-text-right">
                <p className="large">You can come.<br />Just bring money.</p>
              </div>
            </div>
          </Text>
          <BeautyParlor />
          <Text spacing="lg" watermarkNum={3}>
            <div className="columns">
              <div className="column is-8">
                <p className="h6">
                  Club Rule
                </p>
                <p className="large">
                  Knock twice or don't knock at all.
                </p>
              </div>
            </div>
          </Text>
          <BarberShop />
          <Text spacing="lg" watermarkNum={4}>
            <div className="columns">
              <div className="column is-10">
                <p className="large">Scrolling won't make your hair look any better.</p>
              </div>
            </div>
          </Text>
          <Contact />
          <Testimonials />
          <Faq />
          <Instagram />
          <SpotifyBar
            spotifyLink="https://open.spotify.com/user/fsqbkd5ptilv1dr03lb5ebg98?si=3XGU_HF2QdS3O6Snjx8EVg"
          >
            <span className="h6">Vibe Check</span>
            <h2 className="h3">Check out what's playing at the Haus.</h2>
          </SpotifyBar>
        </main>
      </Layout>
    </>
  )
}

export default IndexPage
